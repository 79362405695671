<template>
    <b-row>
        <b-col cols="6">
            <b-form-group>
                <label>Cód TUSS</label>
                <b-form-input 
                    id="cod_tuss"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cod_tuss"
                />
            </b-form-group>
        </b-col>
        <b-col cols="6">
            <b-form-group>
                <label>Cód item</label>
                <b-form-input 
                    id="cod_item"
                    autocomplete="off"
                    maxlength="20"
                    v-model="form.cod_item"
                />
            </b-form-group>
        </b-col>
        <b-col cols="12">
            <b-form-group>
                <label>Item</label>
                <b-form-input 
                    id="item"
                    autocomplete="off"
                    v-model="form.item"
                />
            </b-form-group>
        </b-col>

        <b-col cols="3">
            <b-form-group>
                <label>Cód Lab</label>
                <b-form-input 
                    id="cod_lab"
                    autocomplete="off"
                    v-model="form.cod_lab"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Laboratório</label>
                <b-form-input 
                    id="lab"
                    autocomplete="off"
                    v-model="form.lab"
                />
            </b-form-group>
        </b-col>
        
        <b-col cols="3">
            <b-form-group>
                <label>Cód apres</label>
                <b-form-input 
                    id="cod_apres"
                    autocomplete="off"
                    maxlength="10"
                    v-model="form.cod_apres"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Apres</label>
                <b-form-input 
                    id="apres"
                    autocomplete="off"
                    v-model="form.apres"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Preço apres</label>
                <money 
                    id="preco_apres"
                    v-model="form.preco_apres"
                    class="form-control"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Fracionamento</label>
                <b-form-input 
                    id="fracionamento"
                    type="number"
                    autocomplete="off"
                    v-model="form.fracionamento"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Flag preço</label>
                <multiselect
                    id="flag_preco"
                    v-model="form.flag_preco"
                    :options="['PMC', 'PFB']"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown />
                        </div>
                    </template>
                </multiselect>
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Preço fração</label>
                <b-form-input 
                    id="preco_frac"
                    type="number"
                    autocomplete="off"
                    v-model="form.preco_frac"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Edição</label>
                <b-form-input 
                    id="edicao"
                    type="number"
                    autocomplete="off"
                    v-model="form.edicao"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>IPI</label>
                <b-form-input 
                    id="ipi"
                    type="number"
                    autocomplete="off"
                    v-model="form.ipi"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Pis cofins</label>
                <multiselect
                    id="pis_cofins"
                    v-model="form.pis_cofins"
                    :options="['S', 'N']"
                    :showLabels="false"
                    :searchable="false"
                    :allowEmpty="false"
                    placeholder="Selecionar"
                    class="with-border"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown />
                        </div>
                    </template>
                    <template slot="noOptions">Nenhuma opção</template>
                    <template slot="noResult">Nenhum resultado</template>
                </multiselect>
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>EAN</label>
                <b-form-input 
                    id="ean"
                    type="number"
                    autocomplete="off"
                    v-model="form.ean"
                />
            </b-form-group>
        </b-col>
        <b-col cols="3">
            <b-form-group>
                <label>Atualização edição</label>
                <b-form-input 
                    id="edicao_updt"
                    type="number"
                    autocomplete="off"
                    v-model="form.edicao_updt"
                />
            </b-form-group>
        </b-col>
    </b-row>
</template>
<script>
export default {
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    props: {},
    data() {
        return {
            form: {}
        }
    },
    methods: {},
    watch: {
        form: {
            deep: true,
            immediate: true,
            handler(form) {
                this.$emit('input', form)
            }
        }
    
    }
}
</script>
<style lang="scss" scoped>

</style>